<template>
  <div>
    <el-form ref="form" status-icon :model="form" :rules="rules">
      <el-form-item prop="reason" size="medium">
        <span class="input--label d-block" :class="mode ? 'text__day2' : 'text__night2'">
          {{ $t("message.guest_coming_reason") }}
        </span>
        <crm-input
          :inputValue="form.reason"
          v-model="form.reason"
          :placeholder="$t('message.guest_coming_reason')"
          :class="mode ? 'input__day' : 'input__night'"
        ></crm-input>
      </el-form-item>
    </el-form>
    <span slot="footer" class="dialog-footer">
      <el-button type="primary" @click="submit()" v-loading="loadingButton">
        Сохранить
      </el-button>
      <el-button type="warning" @click="close()"> Закрыть </el-button>
    </span>
  </div>
</template>
<script>
import { i18n } from "@/utils/i18n";
import dialog from "@/utils/mixins/dialog";
import { mapGetters, mapActions } from "vuex";
export default {
  mixins: [dialog],
  data() {
    return {
      form: {},
      loadingButton: false,
    };
  },  
  computed: {
    ...mapGetters({
      rules: "guestComingReason/rules",
      model: "guestComingReason/model",
      columns: "guestComingReason/columns",
      mode: "MODE"
    }),
  },
  methods: {
    ...mapActions({
      save: "guestComingReason/store",
      empty: "guestComingReason/empty",
      guestComingReason: "guestComingReason/inventory",
    }),
    submit() {
      this.$refs["form"].validate((valid) => {
        if (valid && !this.loadingButton) {
          this.loadingButton = true;         
          this.save(this.form)
            .then((res) => {
              this.$alert(res);
              if (res.status == 201) {
                this.$parent.$parent.form.guest_coming_reason_id = res.data.result.data.guest_coming_reason_id;
                this.guestComingReason();
                this.close();                      
              }
              this.loadingButton = false;
            })
            .catch((err) => {
              this.loadingButton = false;
              this.$alert(err);
            });
        }
      });
    },
    close() {
      this.form = JSON.parse(
        JSON.stringify({
          reason: null,
        })
      );
      this.$parent.$parent.dialogCreateComingReason = false;
    },
  },
};
</script>
